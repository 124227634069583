<template>
    <BlockContainer
        :background-color="data.backgroundColor"
        :bottom-angle="data.bottomAngle"
        :angle-background-color="
            data.nextBackgroundColor ? data.nextBackgroundColor : null
        "
        :arrow-callout="data.arrowCallout"
    >
        <div class="relative py-14">
            <h2 class="mb-8">{{ data.heading }}</h2>
            <div class="gap-4 md:flex">
                <div class="w-full md:-mb-4 md:w-1/2">
                    <Card
                        v-for="(card, index) in cardsFirstColumn"
                        :key="index"
                        v-editable="card.vEditable"
                        class="mb-4 w-full self-start px-4 py-2"
                    >
                        <Collapse icon="plusminus" :is-expanded="0 === index">
                            <template #title>
                                <h3>{{ card.title }}</h3>
                            </template>

                            <div class="border-t border-grind py-6">
                                <WysiwygContent :content="card.content" />
                            </div>
                        </Collapse>
                    </Card>
                </div>
                <div class="w-full md:-mb-4 md:w-1/2">
                    <Card
                        v-for="(card, index) in cardsSecondColumn"
                        :key="'collapse-card-' + index"
                        v-editable="card.vEditable"
                        class="mb-4 w-full self-start px-4 py-2"
                    >
                        <Collapse
                            icon="plusminus"
                            :is-expanded="0 === index && isMdOrGreater"
                        >
                            <template #title>
                                <h3>{{ card.title }}</h3>
                            </template>

                            <div class="border-t border-grind py-6">
                                <WysiwygContent :content="card.content" />
                            </div>
                        </Collapse>
                    </Card>
                </div>
            </div>
            <div v-if="data.buttons" class="mt-8 md:text-center">
                <Button
                    v-for="(button, key) in data.buttons"
                    :key="key"
                    :button="button"
                    class="w-full md:w-auto"
                />
            </div>
        </div>
    </BlockContainer>
</template>

<script setup lang="ts">
import CollapseCardGroupType from '~/types/CollapseCardGroupType';
import Button from '~/components/page-building/components/Button.vue';
import BlockContainer from '~/components/BlockContainer.vue';
import Card from '~/components/page-building/components/Card.vue';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';

const props = defineProps<{
    data: CollapseCardGroupType;
}>();

const middle = ref(Math.ceil(props.data.cards.length / 2));

const cardsFirstColumn = computed(() => {
    return props.data.cards.slice(0, middle.value);
});

const cardsSecondColumn = computed(() => {
    return props.data.cards.slice(middle.value);
});

const isMdOrGreater = useBreakpoints(breakpointsTailwind).greaterOrEqual('md');
</script>
